import { useAuth0 } from "@auth0/auth0-react";
import { Popover, Tooltip } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { SyntheticEvent } from "react";
import AccountCard from "./AccountCard";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  popover: {},
}));

const AccountButton = () => {
  const classes = useStyles();

  const { isLoading, isAuthenticated, user, loginWithRedirect } = useAuth0();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "profile-popover" : undefined;

  return (
    <div className={classes.root}>
      {!isLoading && isAuthenticated && user ? (
        <div>
          <Tooltip title={user.name || ""}>
            <IconButton
              aria-describedby={id}
              onClick={handleClick}
              data-cy={"app-bar-account-button"}
            >
              <Avatar className={classes.avatar} src={user.picture} />
            </IconButton>
          </Tooltip>
          <Popover
            id={id}
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <AccountCard />
          </Popover>
        </div>
      ) : (
        <Button color="secondary" variant={"text"} onClick={loginWithRedirect}>
          Sign in
        </Button>
      )}
    </div>
  );
};

export default AccountButton;
