// Admin
export const ADMIN = "ADMIN";
export const SUPER = "SUPER";

// Agents
export const COMPLIANCE_OWNER = "COMPLIANCE_OWNER";
export const COMPLIANCE_OFFICER = "COMPLIANCE_OFFICER";
export const CONTRACTS_OWNER = "CONTRACTS_OWNER";
export const CONTRACTS_OFFICER = "CONTRACTS_OFFICER";
export const RISK_ADMIN = "RISK_ADMIN";
export const TRUSTEE_SERVICES_OWNER = "TRUSTEE_SERVICES_OWNER";
export const TRUSTEE_SERVICES_OFFICER = "TRUSTEE_SERVICES_OFFICER";
export const SENTINEL_OWNER = "SENTINEL_OWNER";

// External
export const CLIENT = "CLIENT";
export const SCHEME_MANAGER = "SCHEME_MANAGER";
export const SERVICE_PROVIDER = "SERVICE_PROVIDER";

// Services
export const SUBSCRIBER_SERVICE = "SUBSCRIBER_SERVICE";

export const VIEWER = "VIEWER";

// Role groupings
export const EXTERNAL_ROLES = [CLIENT, SERVICE_PROVIDER, SCHEME_MANAGER];
export const ADMIN_ROLES = [ADMIN, SUPER];
export const AGENT_ROLES = [
  COMPLIANCE_OWNER,
  COMPLIANCE_OFFICER,
  CONTRACTS_OWNER,
  CONTRACTS_OFFICER,
  RISK_ADMIN,
  TRUSTEE_SERVICES_OWNER,
  TRUSTEE_SERVICES_OFFICER,
];
