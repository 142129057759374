import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Auth0User, getTenantIdsFromUser } from "../auth/Auth0User";
import Forbidden from "./forbidden";
import useTenant from "./use-tenant";

const AUTH0_PARAMS = ["code", "state"];
const queryContainsAuth0Params = () => {
  let contains = false;
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    contains = AUTH0_PARAMS.filter((p) => params.has(p)).length > 0;
  }
  return contains;
};

/**
 * HOC to ensure that a tenant parameter is included in the url. If not, it sets the first
 * tenant in the user's token as the tenant parameter.
 *
 * @param Component
 */
const ensureTenantParam =
  <P extends object>(Component: React.ComponentType<P>): React.FC<P> =>
  (props: P) => {
    const { user: auth0User, isAuthenticated, isLoading, logout } = useAuth0();
    const { getActiveTenantId, setActiveTenantId, getLastTenantId } =
      useTenant();

    // Wait for auth0User to be set
    if (!auth0User || !isAuthenticated || isLoading) {
      return <div>Authenticating.</div>;
    }

    // Wait for auth0 client to remove auth0 params
    if (queryContainsAuth0Params()) {
      return <div>Authenticating...</div>;
    }

    // Check if user has tenants
    const allUserTenants = getTenantIdsFromUser(auth0User as Auth0User);
    if (allUserTenants.length === 0) {
      return (
        <Forbidden
          logout={() => logout({ returnTo: window.location.origin })}
        />
      );
    }

    const activeTenantId = getActiveTenantId();

    if (!activeTenantId || allUserTenants.indexOf(activeTenantId) === -1) {
      // First try to use the value stored in local storage if user token has that tenantId
      const lastTenantId = getLastTenantId();
      if (!!lastTenantId && allUserTenants.indexOf(lastTenantId) !== -1) {
        setActiveTenantId(lastTenantId);
        return <div />; // let parameter take place and page refresh before rendering real component
      }
      // Second (and last) attempt - use first tenant from token
      const firstUserTenantId = allUserTenants[0];
      setActiveTenantId(firstUserTenantId);
      return <div />; // let parameter take place and page refresh before rendering real component
    }

    return <Component {...props} />;
  };

export default ensureTenantParam;
