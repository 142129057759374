import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

interface Props {
  logout: () => void;
}

const Forbidden = ({ logout }: Props) => {
  return (
    <Grid container direction="column" style={{ margin: 24 }}>
      <Grid item>
        <Typography variant="h3" gutterBottom>
          Forbidden
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" gutterBottom>
          Sorry, you're account is not configured to access this application.
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" onClick={logout}>
          Sign out
        </Button>
      </Grid>
    </Grid>
  );
};

export default Forbidden;
