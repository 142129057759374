import IconButton from "@material-ui/core/IconButton";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: theme.palette.common.white,
    margin: "0 4px",
  },
}));

interface Props {
  onClick: () => void;
}

const MenuButton = ({ onClick }: Props) => {
  const classes = useStyles();
  return (
    <IconButton
      className={classes.menuButton}
      aria-label="Menu"
      color="inherit"
      onClick={onClick}
      data-cy="menu"
    >
      <MenuIcon />
    </IconButton>
  );
};

export default MenuButton;
