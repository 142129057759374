import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
  appLogoLink: {
    display: "inherit",
  },
  appLogo: {
    maxHeight: 42,
    marginLeft: 4,
  },
}));

interface Props {
  logoUrl?: string;
  title?: string;
  link?: string;
}

const AppBarLogo = ({ logoUrl, title, link }: Props) => {
  const classes = useStyles();

  return (
    <a className={classes.appLogoLink} href={link}>
      <img
        className={classes.appLogo}
        src={logoUrl}
        alt={title}
        title={title}
        data-cy="certaneLogo"
      />
    </a>
  );
};

export default AppBarLogo;
