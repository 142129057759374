import { useAuth0 } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import { TenantApiClient } from "./integration/TenantApiClient";
import TenantContext, { TenantContextInterface } from "./tenant-context";
import { Tenant } from "./tenant-types";

const TENANT_LOCAL_STORAGE_KEY = "x-tenant-id";
export const TENANT_PARAM = "t";

interface Props {
  children?: React.ReactNode;
  tenantApiBasePath?: string;
}

const TenantProvider = ({
  children,
  tenantApiBasePath,
}: Props): JSX.Element => {
  const { getAccessTokenSilently } = useAuth0();
  const [cachedTenant, setCachedTenant] = useState<Tenant | null>(null);

  useEffect(() => {
    (async () => {
      const tenantId = getActiveTenantId();

      if (!tenantId) return null;

      const memoizedGetTenant = async (
        tenantId: string
      ): Promise<Tenant | null> => {
        const accessToken = await getAccessTokenSilently();
        const client = new TenantApiClient({ basePath: tenantApiBasePath });
        return client.get(tenantId, accessToken).then((r) => r.data || null);
      };

      const tenant = await memoizedGetTenant(tenantId);
      setCachedTenant(tenant);

      return tenant;
    })();
  }, []);

  const getActiveTenantStoredProperty = (): string | null => {
    if (window?.localStorage) {
      return window.localStorage.getItem(TENANT_LOCAL_STORAGE_KEY);
    }
    return null;
  };

  const setActiveTenantStoredProperty = (tenantId: string): void => {
    if (window?.localStorage) {
      window.localStorage.setItem(TENANT_LOCAL_STORAGE_KEY, tenantId);
    }
  };

  const getActiveTenantId = useCallback(() => {
    // console.log("getActiveTenantId");
    if (window?.location.search) {
      const params = new URLSearchParams(window.location.search);
      const tenantParam = params.get(TENANT_PARAM);
      if (tenantParam) {
        return tenantParam;
      }
    }

    return null;
  }, []);

  const setActiveTenantId = useCallback((tenantId: string): void => {
    // console.log("setActiveTenantId", tenantId);
    if (window?.location) {
      const params = new URLSearchParams(window.location.search);
      if (!params.has(TENANT_PARAM) || params.get(TENANT_PARAM) !== tenantId) {
        params.set(TENANT_PARAM, tenantId);
        window.location.search = `?${params.toString()}`;
      }
    }

    setActiveTenantStoredProperty(tenantId);
  }, []);

  const contextValue: TenantContextInterface = {
    getActiveTenantId,
    setActiveTenantId,
    getLastTenantId: getActiveTenantStoredProperty,
    tenant: cachedTenant,
  };

  return (
    <TenantContext.Provider value={contextValue}>
      {children}
    </TenantContext.Provider>
  );
};

export default TenantProvider;
