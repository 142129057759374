import { useAuth0 } from "@auth0/auth0-react";
import { MenuItem, Select } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";
import { TenantSummary } from "./tenant-types";
import useTenant from "./use-tenant";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  select: {
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
  },
}));

interface Props {
  tenants: TenantSummary[];
}

const TenantSwitcher = ({ tenants }: Props) => {
  const classes = useStyles();
  const { isLoading, user } = useAuth0();
  const { getActiveTenantId, setActiveTenantId } = useTenant();

  if (isLoading || !user) return <div />;

  if (tenants.length <= 1) return <div />;

  return (
    <Select
      classes={{
        root: classes.root,
        select: classes.select,
        icon: classes.icon,
      }}
      variant={"filled"}
      value={getActiveTenantId()}
      onChange={(e) => setActiveTenantId(e.target.value as string)}
      disableUnderline
    >
      {tenants.map((tenant) => (
        <MenuItem
          key={tenant.id}
          value={tenant.id}
          onClick={() => setActiveTenantId(tenant.id)}
        >
          {tenant.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default TenantSwitcher;
