import AppBar from "@material-ui/core/AppBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar";
import React, { ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.black,
    boxShadow: "none",
  },
  toolbar: {
    display: "flex",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  leftContainer: {
    display: "flex",
    flex: "1 0 auto",
    alignItems: "center",
  },
  centerContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
  rightContainer: {
    display: "flex",
    flex: "1 0 auto",
    alignItems: "center",
    justifyContent: "flex-end",
    "&> *": {
      marginLeft: theme.spacing(1),
    },
  },
}));

interface Props {
  leadingIcon?: ReactNode | ReactNode[];
  headline?: ReactNode | ReactNode[];
  trailingIcon?: ReactNode | ReactNode[];
}

const ArcadiaAppBar = ({ leadingIcon, headline, trailingIcon }: Props) => {
  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appBar} data-cy="appBar">
      <Toolbar className={classes.toolbar}>
        <div className={classes.leftContainer}>
          {leadingIcon}
          {headline}
        </div>
        <div className={classes.centerContainer} />
        <div className={classes.rightContainer}>{trailingIcon}</div>
      </Toolbar>
    </AppBar>
  );
};

export default ArcadiaAppBar;
