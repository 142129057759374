import { useAuth0 } from "@auth0/auth0-react";
import Drawer from "@material-ui/core/Drawer";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React, { ReactNode, useMemo } from "react";
import MenuButton from "../appbar/MenuButton";
import authorizer from "../auth/authorizer";
import { includeTenantParam } from "../tenant/tenant-util";
import useTenant from "../tenant/use-tenant";
import NavMenuSection, { NavMenuSectionConfig } from "./NavMenuSection";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    background: theme.palette.common.black,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

interface Options {
  arcadiaBaseUrl?: string;
  reggaeBaseUrl?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  headline: ReactNode;
  options?: Options;
}

const NavDrawer: React.FC<Props> = ({
  open,
  onClose,
  headline,
  options,
}: Props) => {
  const classes = useStyles();
  const { user } = useAuth0();
  const { tenant } = useTenant();
  const featureFlags = tenant?.featureFlags;

  const arcadiaLink = (path: string) =>
    includeTenantParam(`${options?.arcadiaBaseUrl}${path}`);
  const reggaeLink = (path: string) =>
    includeTenantParam(`${options?.reggaeBaseUrl}${path}`);
  const isSelected = (regexp: string | RegExp): boolean =>
    !!window?.location.pathname.match(regexp);

  const workItemSection: NavMenuSectionConfig = {
    headline: "Work items",
    hidden: !authorizer.check("work-item", "viewer", user, tenant?.id),
    items: [
      {
        icon: "dashboard",
        label: "Dashboard",
        link: arcadiaLink("/dashboard"),
        selected: isSelected(/^\/dashboard/),
      },
      {
        icon: "work",
        label: "Work items",
        link: arcadiaLink("/work-items"),
        selected: isSelected(/^\/work-items(?!\/draft)/),
      },
      {
        icon: "edit",
        label: "Drafts",
        link: arcadiaLink("/work-items/drafts"),
        selected: isSelected(/^\/work-items\/drafts/),
        hidden: !authorizer.check("work-item", "editor", user, tenant?.id),
      },
      {
        icon: "event",
        label: "Calendar",
        link: arcadiaLink("/work-item-schedules"),
        selected: isSelected(/^\/work-item-schedules/),
        hidden:
          !featureFlags?.["compliance-task"] ||
          !authorizer.check(
            "work-item:compliance-task",
            "viewer",
            user,
            tenant?.id
          ),
      },
    ],
  };

  const riskSection: NavMenuSectionConfig = {
    headline: "Risk",
    hidden:
      !featureFlags?.risk ||
      !authorizer.check("risk", "viewer", user, tenant?.id),
    items: [
      {
        icon: "dashboard",
        label: "Dashboard",
        link: arcadiaLink("/risk"),
        selected: isSelected(/^\/risk$/),
        hidden: !authorizer.check("risk", "editor", user, tenant?.id),
      },
      {
        icon: "warning",
        label: "Risks",
        link: arcadiaLink("/risk/risks"),
        selected: isSelected(
          /^\/risk\/risks(?!\/actions|\/reassessments|\/measure-updates)/
        ),
      },
      {
        icon: "assignment",
        label: "Risk actions",
        link: arcadiaLink("/risk/risks/actions"),
        selected: isSelected(/^\/risk\/risks\/actions/),
      },
      {
        icon: "assessment",
        label: "Risk reassessments",
        link: arcadiaLink("/risk/risks/reassessments"),
        selected: isSelected(/^\/risk\/risks\/reassessments/),
      },
      {
        icon: "straighten",
        label: "Measure updates",
        link: arcadiaLink("/risk/risks/measure-updates"),
        selected: isSelected(/^\/risk\/risks\/measure-updates/),
      },
    ],
  };

  const reggaeSection: NavMenuSectionConfig = {
    headline: "Regulatory obligations",
    hidden:
      !featureFlags?.reggae ||
      !authorizer.check("regulatory-obligation", "viewer", user, tenant?.id),
    items: [
      {
        icon: "menu_book",
        label: "Regulatory sources",
        link: reggaeLink("/reg-sources"),
        selected: isSelected(/^\/reg-sources/),
      },
      {
        icon: "dashboard",
        label: "Obligation themes",
        link: reggaeLink("/themes"),
        selected: isSelected(/^\/themes/),
      },
    ],
  };

  const controlSection: NavMenuSectionConfig = {
    headline: "Controls library",
    hidden:
      !featureFlags?.control ||
      !authorizer.check("control", "viewer", user, tenant?.id),
    items: [
      {
        icon: "control_camera",
        label: "Controls",
        link: arcadiaLink("/controls"),
        selected: isSelected(/^\/controls/),
      },
    ],
  };

  const auditSection: NavMenuSectionConfig = {
    headline: "Audits",
    hidden:
      !featureFlags?.audit ||
      !authorizer.check("audit", "viewer", user, tenant?.id),
    items: [
      {
        icon: "list_alt",
        label: "Audits",
        link: arcadiaLink("/audits"),
        selected: isSelected(/^\/audits/),
      },
    ],
  };

  const metropolisSection: NavMenuSectionConfig = {
    headline: "Metropolis",
    hidden:
      !featureFlags?.metropolis ||
      !authorizer.check("metropolis", "viewer", user, tenant?.id),
    items: [
      {
        icon: "cloud",
        label: "Content",
        link: arcadiaLink("/metropolis"),
        selected: isSelected(/^\/metropolis(?!\/categories)/),
      },
      {
        icon: "category",
        label: "Manage categories",
        link: arcadiaLink("/metropolis/categories"),
        selected: isSelected(/^\/metropolis\/categories/),
        hidden: !authorizer.check("metropolis", "editor", user, tenant?.id),
      },
    ],
  };

  const sentinelSection: NavMenuSectionConfig = {
    headline: "Sentinel",
    hidden:
      !featureFlags?.sentinel ||
      !authorizer.check("sentinel", "viewer", user, tenant?.id),
    items: [
      {
        icon: "web",
        label: "Digital assets",
        link: arcadiaLink("/sentinel/digital-assets"),
        selected: isSelected(/^\/sentinel\/digital-assets/),
      },
      {
        icon: "compare_arrows",
        label: "Website differences",
        link: arcadiaLink("/sentinel/differences"),
        selected: isSelected(/^\/sentinel\/differences/),
      },
    ],
  };

  const administrationSection: NavMenuSectionConfig = {
    headline: "Administration",
    items: [
      {
        icon: "import_export",
        label: "Bulk import",
        link: arcadiaLink("/admin/bulk-import"),
        selected: isSelected(/^\/admin\/bulk-import/),
        hidden:
          !(featureFlags?.complaint || featureFlags?.["compliance-task"]) ||
          !authorizer.check(
            "tenant-admin:bulk-import",
            "viewer",
            user,
            tenant?.id
          ),
      },
      {
        icon: "account_balance",
        label: "Funds",
        link: arcadiaLink("/admin/funds"),
        selected: isSelected(/^\/admin\/funds/),
        hidden: !authorizer.check(
          "tenant-admin:fund",
          "viewer",
          user,
          tenant?.id
        ),
      },
      {
        icon: "money",
        label: "Products",
        link: arcadiaLink("/admin/products"),
        selected: isSelected(/^\/admin\/products/),
        hidden: !authorizer.check(
          "tenant-admin:product",
          "viewer",
          user,
          tenant?.id
        ),
      },
      {
        icon: "mail",
        label: "Subscriptions",
        link: arcadiaLink("/admin/subscriptions"),
        selected: isSelected(/^\/admin\/subscriptions/),
        hidden: !authorizer.check(
          "tenant-admin:subscription",
          "viewer",
          user,
          tenant?.id
        ),
      },
      {
        icon: "local_offer",
        label: "Tags",
        link: arcadiaLink("/admin/tags"),
        selected: isSelected(/^\/admin\/tags/),
        hidden: !authorizer.check(
          "tenant-admin:tag",
          "viewer",
          user,
          tenant?.id
        ),
      },
    ],
  };

  const settingSection: NavMenuSectionConfig = useMemo(
    () => ({
      headline: "Settings",
      hidden: !authorizer.check("setting", "viewer", user, tenant?.id),
      items: [
        {
          icon: "update",
          label: "Bulk updates",
          link: arcadiaLink("/admin/bulk-updates"),
          selected: isSelected(/^\/admin\/bulk-updates/),
        },
        {
          icon: "near_me",
          label: "Business Service Areas",
          link: arcadiaLink("/admin/business-service-areas"),
          selected: isSelected(/^\/admin\/business-service-areas/),
        },
        {
          icon: "business",
          label: "Organisations",
          link: arcadiaLink("/admin/organisations"),
          selected: isSelected(/^\/admin\/organisations/),
        },
        {
          icon: "timer",
          label: "SLAs",
          link: arcadiaLink("/admin/slas"),
          selected: isSelected(/^\/admin\/slas/),
        },
        {
          icon: "group_work",
          label: "Tenants",
          link: arcadiaLink("/admin/tenants"),
          selected: isSelected(/^\/admin\/tenants/),
        },
        {
          icon: "group",
          label: "User groups",
          link: arcadiaLink("/admin/user-groups"),
          selected: isSelected(/^\/admin\/user-groups/),
        },
        {
          icon: "person",
          label: "Users",
          link: arcadiaLink("/admin/users"),
          selected: isSelected(/^\/admin\/users/),
        },
        {
          icon: "upload",
          label: "Views",
          link: arcadiaLink("/admin/views"),
          selected: isSelected(/^\/admin\/views/),
        },
      ],
    }),
    [user, tenant]
  );

  return (
    <Drawer open={open} onClose={onClose}>
      <div className={classes.drawerHeader}>
        <MenuButton onClick={onClose} />
        {headline}
      </div>
      <NavMenuSection config={workItemSection} />
      <NavMenuSection config={riskSection} />
      <NavMenuSection config={reggaeSection} />
      <NavMenuSection config={controlSection} />
      <NavMenuSection config={auditSection} />
      <NavMenuSection config={metropolisSection} />
      <NavMenuSection config={sentinelSection} />
      <NavMenuSection config={administrationSection} />
      <NavMenuSection config={settingSection} />
    </Drawer>
  );
};

export default NavDrawer;
