import Typography from "@material-ui/core/Typography";
import * as React from "react";
import authAuthorizer from "./auth/authorizer";
import * as authRoles from "./auth/roles";

interface Props {
  text: string;
}

export const ExampleComponent = ({ text }: Props) => {
  return (
    <div>
      <Typography variant={"h6"}>{text}</Typography>
    </div>
  );
};

export { default as AccountButton } from "./appbar/AccountButton";
export { default as AccountCard } from "./appbar/AccountCard";
export { default as AppBarLogo } from "./appbar/AppBarLogo";
export { default as ArcadiaAppBar } from "./appbar/ArcadiaAppBar";
export { default as MenuButton } from "./appbar/MenuButton";

export const roles = authRoles;
export const authorizer = authAuthorizer;

export type { JSONAPI, JSONAPIError, JSONAPILinks } from "./jsonapi";
export { createError, createResource } from "./jsonapi";

export { default as NavDrawer } from "./nav-drawer/NavDrawer";

export { default as TenantProvider } from "./tenant/tenant-provider";
export { getActiveTenantParam, includeTenantParam } from "./tenant/tenant-util";
export type {
  FeatureFlags,
  Tenant,
  TenantSummary,
} from "./tenant/tenant-types";
export { default as useTenant } from "./tenant/use-tenant";
export { default as TenantSwitcher } from "./tenant/TenantSwitcher";
export { default as ensureTenantParam } from "./tenant/ensure-tenant-param";
