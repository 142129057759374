import * as roles from "./roles";

export type Resource = {
  [relation: string]: string[]; // e.g. viewer: [ADMIN, COMPLIANCE_OWNER, VIEWER], editor: [ADMIN, COMPLIANCE_OWNER].
};

export const resources: Record<string, Resource> = {
  audit: {
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.VIEWER,
    ],
    editor: [roles.ADMIN, roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
  },
  control: {
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.RISK_ADMIN,
      roles.VIEWER,
    ],
    editor: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.RISK_ADMIN,
    ],
  },
  metropolis: {
    editor: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.CLIENT,
    ],
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.VIEWER,
    ],
  },
  "regulatory-obligation": {
    viewer: [
      roles.ADMIN,
      roles.RISK_ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
    ],
  },
  risk: {
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.RISK_ADMIN,
      roles.VIEWER,
    ],
    editor: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.RISK_ADMIN,
    ],
  },
  sentinel: {
    viewer: [roles.ADMIN, roles.SENTINEL_OWNER],
  },
  setting: {
    viewer: [roles.ADMIN],
  },
  "tenant-admin:bulk-import": {
    viewer: [roles.ADMIN, roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
  },
  "tenant-admin:fund": {
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.CONTRACTS_OWNER,
      roles.CONTRACTS_OFFICER,
      roles.RISK_ADMIN,
      roles.TRUSTEE_SERVICES_OWNER,
      roles.TRUSTEE_SERVICES_OFFICER,
    ],
  },
  "tenant-admin:product": {
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.CONTRACTS_OWNER,
      roles.CONTRACTS_OFFICER,
      roles.RISK_ADMIN,
      roles.TRUSTEE_SERVICES_OWNER,
      roles.TRUSTEE_SERVICES_OFFICER,
    ],
  },
  "tenant-admin:subscription": {
    viewer: [
      roles.ADMIN,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.CONTRACTS_OWNER,
      roles.CONTRACTS_OFFICER,
      roles.RISK_ADMIN,
      roles.TRUSTEE_SERVICES_OWNER,
      roles.TRUSTEE_SERVICES_OFFICER,
    ],
  },
  "tenant-admin:tag": {
    viewer: [roles.ADMIN, roles.RISK_ADMIN, roles.COMPLIANCE_OWNER],
  },
  "work-item": {
    viewer: [
      roles.ADMIN,
      roles.CLIENT,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.CONTRACTS_OWNER,
      roles.CONTRACTS_OFFICER,
      roles.RISK_ADMIN,
      roles.SERVICE_PROVIDER,
      roles.TRUSTEE_SERVICES_OWNER,
      roles.TRUSTEE_SERVICES_OFFICER,
      roles.VIEWER,
    ],
    editor: [
      roles.ADMIN,
      roles.CLIENT,
      roles.COMPLIANCE_OWNER,
      roles.COMPLIANCE_OFFICER,
      roles.CONTRACTS_OWNER,
      roles.CONTRACTS_OFFICER,
      roles.RISK_ADMIN,
      roles.SERVICE_PROVIDER,
      roles.TRUSTEE_SERVICES_OWNER,
      roles.TRUSTEE_SERVICES_OFFICER,
    ],
  },
  "work-item:compliance-task": {
    viewer: [roles.ADMIN, roles.COMPLIANCE_OWNER, roles.COMPLIANCE_OFFICER],
  },
};
