import { User } from "@auth0/auth0-react";
import { TenantClaim } from "./TenantClaim";

export const TENANTS_CLAIM = "https://arcadia.sargon.com/tenants";

/**
 * Shape of user object returned by auth0.
 *
 * E.g. `const { user } = useAuth0();`
 */
export interface Auth0User extends User {
  name: string;
  email: string;
  picture: string;
  [TENANTS_CLAIM]: Record<string, TenantClaim>;
}

export const getTenantIdsFromUser = (user: Auth0User): string[] => {
  const tenantsClaim = user[TENANTS_CLAIM] || {};
  return Object.keys(tenantsClaim);
};
