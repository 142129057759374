import { User } from "@auth0/auth0-react";
import _ from "lodash";
import { Resource, resources } from "./authorization-model";

const check = (
  resourceId: string,
  relation: string,
  user?: User,
  tenantId?: string
): boolean => {
  if (!resourceId || !relation || !user || !tenantId) return false;

  const resource = resources[resourceId];

  if (!resource) {
    throw new Error(`Invalid resourceId: ${resourceId}`);
  }

  if (!resource[relation]) {
    throw new Error(`Invalid relation: ${relation}`);
  }

  return (
    _.intersection(
      resource[relation].map((r) => r.toLowerCase()),
      user["https://arcadia.sargon.com/tenants"][tenantId].roles
    ).length > 0
  );
};

const read = (resourceId: string): Resource => {
  const resource = resources[resourceId];

  if (!resource) {
    throw new Error(`Invalid resourceId: ${resourceId}`);
  }

  return resource;
};

export default {
  check,
  read,
};
