import { createContext } from "react";
import { Tenant } from "./tenant-types";

export interface TenantContextInterface {
  getActiveTenantId: () => string | null;
  setActiveTenantId: (tenantId: string) => void;
  getLastTenantId: () => string | null;
  tenant: Tenant | null;
}

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <TenantProvider>.");
};

const initialContext = {
  getActiveTenantId: stub,
  setActiveTenantId: stub,
  getLastTenantId: stub,
  tenant: null,
};

const TenantContext = createContext<TenantContextInterface>(initialContext);

export default TenantContext;
