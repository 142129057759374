import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import * as React from "react";
import { TENANTS_CLAIM } from "../auth/Auth0User";
import useTenant from "../tenant/use-tenant";

const useStyles = makeStyles((theme) => ({
  profilePopoverContent: {
    textAlign: "center",
  },
  avatarPopover: {
    margin: "16px auto",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  logoutPopoverContent: {
    textAlign: "center",
    "&:lastChild": {
      padding: theme.spacing(2),
    },
  },
}));

const toLabel = (str: string) => {
  return str
    .toLowerCase()
    .replace(/(^[a-z])/g, ($1) => $1.toUpperCase())
    .replace(/_/g, " ");
};

const AccountCard = () => {
  const classes = useStyles();

  const { user, logout } = useAuth0();
  const { getActiveTenantId } = useTenant();

  if (!user) return null;

  const roles = (user: any): string[] => {
    const activeTenantId = getActiveTenantId();
    if (!activeTenantId) return [];
    const activeTenant = user[TENANTS_CLAIM][activeTenantId];
    if (!activeTenant) return [];
    activeTenant.roles.sort();
    return activeTenant.roles.map((role: string) => toLabel(role));
  };

  return (
    <Card>
      <CardContent className={classes.profilePopoverContent}>
        <Avatar
          className={classes.avatarPopover}
          alt={user.name}
          src={user.picture}
        />
        <Typography variant={"h6"}>{user.name}</Typography>
        <Typography variant={"body2"}>{user.email}</Typography>
      </CardContent>
      <Divider />
      <CardContent>
        <Typography variant={"subtitle1"} gutterBottom>
          Roles
        </Typography>
        {roles(user).map((role) => (
          <Typography key={role} variant={"body2"}>
            {role}
          </Typography>
        ))}
      </CardContent>
      <Divider />
      <CardContent className={classes.logoutPopoverContent}>
        <Button
          variant={"outlined"}
          onClick={() => logout({ returnTo: window.location.origin })}
          data-cy={"sign-out-button"}
        >
          Sign out
        </Button>
      </CardContent>
    </Card>
  );
};

export default AccountCard;
