import axios, { AxiosRequestConfig } from "axios";
import { JSONAPI } from "../../jsonapi";
import { Tenant } from "../tenant-types";

interface ApiClientOptions {
  readonly basePath?: string;
}

export class TenantApiClient {
  private readonly options: ApiClientOptions;

  constructor(options: ApiClientOptions) {
    this.options = options;
  }

  private static async fetch(
    url: string,
    config: AxiosRequestConfig
  ): Promise<any> {
    return axios.get(url, config).then((r) => r.data);
  }

  public async get(
    tenantId: string,
    accessToken: string
  ): Promise<JSONAPI<Tenant>> {
    return TenantApiClient.fetch(
      `${this.options.basePath}/api/tenants/${tenantId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );
  }
}
