import { TENANT_PARAM } from "./tenant-provider";

export const getActiveTenantParam = (): string | null => {
  if (window.location.search) {
    const params = new URLSearchParams(window.location.search);
    const tenantParam = params.get(TENANT_PARAM);
    if (tenantParam) {
      return tenantParam;
    }
  }
  return null;
};

export const includeTenantParam = (link: string): string => {
  const activeTenantId = getActiveTenantParam();
  if (!activeTenantId) {
    return link;
  }
  const queryParamsIndex = link.lastIndexOf("?");
  if (queryParamsIndex === -1) {
    return `${link}?${TENANT_PARAM}=${activeTenantId}`;
  }
  const params = new URLSearchParams(link.substring(queryParamsIndex));
  params.set(TENANT_PARAM, activeTenantId);
  return `${link.substring(0, queryParamsIndex)}?${params.toString()}`;
};
