import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import React, { useMemo } from "react";
import NavMenuItem from "./NavMenuItem";

export interface NavMenuSectionConfig {
  headline?: string;
  items: DrawerItem[];
  disabled?: boolean;
  hidden?: boolean;
}

export type DrawerItem = {
  icon: string;
  label: string;
  link?: string;
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
  selected?: boolean;
};

interface Props {
  config: NavMenuSectionConfig;
}

const NavMenuSection: React.FC<Props> = ({ config }: Props) => {
  if (config.hidden) return null;

  const hasVisibleItems = useMemo(
    () =>
      config.items.reduce((result, item) => {
        return result || !item.hidden;
      }, false),
    [config]
  );

  if (!hasVisibleItems) return null;

  return (
    <List dense>
      {config.headline && (
        <ListSubheader disableSticky>{config.headline}</ListSubheader>
      )}
      {config.items.map((item) => {
        if (item.hidden) return null;
        return (
          <NavMenuItem
            key={item.label}
            label={item.label}
            icon={item.icon}
            disabled={item.disabled}
            link={item.link}
            onClick={item.onClick}
            selected={item.selected}
          />
        );
      })}
      <Divider />
    </List>
  );
};

export default NavMenuSection;
