import { Icon } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import React from "react";

interface Props {
  label: string;
  icon: string;
  link?: string;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
}

const useStyles = makeStyles(() => ({
  menuLink: {
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const NavMenuItem: React.FC<Props> = ({
  label,
  icon,
  link,
  onClick,
  disabled,
  selected,
}: Props) => {
  const classes = useStyles();
  return (
    <Link className={classes.menuLink} href={link} color={"inherit"}>
      <ListItem
        onClick={onClick}
        disabled={disabled}
        selected={selected}
        button
      >
        <ListItemIcon>
          <Icon>{icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={label} data-cy={label} />
      </ListItem>
    </Link>
  );
};

export default NavMenuItem;
